import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1248.000000) scale(0.100000,-0.100000)">

<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m5585 1224 c52 -5 95 -22 95 -36 0 -4 -11 -8 -24 -8 -14 0 -28 -4 -31
-10 -8 -13 21 -13 46 1 15 8 24 7 35 -2 11 -9 18 -9 26 -1 9 9 7 12 -10 12
-12 0 -22 5 -22 10 0 12 6 12 53 -1 22 -6 35 -14 31 -21 -5 -7 5 -9 29 -6 20
2 34 2 31 -1 -2 -2 15 -11 38 -18 64 -21 108 -43 108 -53 0 -5 6 -7 13 -4 14
5 16 -33 3 -55 -4 -7 -5 -15 -2 -18 9 -10 34 29 29 44 -6 15 6 17 27 3 13 -9
13 -11 0 -20 -13 -8 -13 -10 2 -10 9 0 19 -6 22 -12 3 -7 3 -2 0 10 -6 23 -6
24 21 6 20 -13 23 -19 13 -25 -10 -7 -8 -9 5 -9 18 0 57 -21 90 -49 18 -15 18
-16 0 -23 -14 -6 -13 -7 7 -5 15 1 36 -8 53 -24 l29 -27 -22 -25 c-12 -14 -37
-28 -56 -32 -22 -4 -34 -12 -34 -22 0 -13 3 -14 12 -5 7 7 16 12 21 12 6 0 -5
-16 -24 -35 -38 -39 -54 -43 -67 -19 -5 9 -15 15 -22 12 -10 -3 -8 -10 9 -27
43 -43 11 -34 -37 11 -26 24 -60 53 -75 62 -15 10 -24 23 -20 29 4 6 0 8 -10
4 -10 -3 -17 -3 -17 1 0 5 -19 17 -42 27 -155 68 -221 93 -261 99 -34 5 -45
11 -40 22 3 7 0 14 -6 14 -6 0 -11 -7 -11 -15 0 -13 -4 -14 -22 -4 -29 15
-314 23 -323 9 -9 -15 -47 -12 -40 3 4 6 1 5 -7 -4 -7 -9 -38 -22 -68 -29 -30
-7 -71 -19 -90 -28 -19 -8 -57 -16 -84 -18 -51 -4 -64 -9 -53 -21 4 -3 19 -1
33 6 15 6 28 10 31 8 6 -7 -131 -71 -146 -68 -7 1 -10 -2 -7 -7 3 -5 -1 -15
-9 -22 -10 -8 -15 -9 -15 -2 0 6 -7 9 -15 6 -8 -4 -13 -10 -10 -14 3 -4 -10
-22 -27 -39 -27 -26 -34 -29 -41 -16 -4 8 -18 14 -30 14 -17 -1 -18 -2 -4 -6
18 -5 24 -23 7 -23 -5 0 -10 -5 -10 -11 0 -6 7 -8 16 -5 8 3 12 2 9 -4 -3 -6
-12 -10 -18 -10 -16 0 -167 156 -167 173 0 8 4 7 10 -3 8 -12 10 -11 10 8 0
24 15 30 23 10 3 -7 6 -1 6 14 1 22 5 25 24 21 15 -3 18 -2 9 4 -10 7 -8 14 8
28 11 10 23 15 27 12 3 -4 1 -7 -6 -7 -7 0 -9 -5 -6 -10 9 -15 25 -2 25 21 0
12 11 24 28 32 15 6 31 18 35 25 7 10 12 9 26 -7 10 -12 21 -21 25 -21 13 0 5
23 -11 36 -17 12 -15 14 15 26 17 7 32 16 32 20 0 4 6 5 14 2 7 -3 16 2 19 10
4 9 14 16 22 16 10 0 13 -5 9 -12 -4 -7 -3 -8 5 -4 6 4 9 11 6 16 -3 5 -1 11
5 15 5 3 10 -3 10 -13 0 -24 17 -32 53 -25 40 8 33 20 -8 15 -36 -4 -42 2 -24
28 8 11 12 12 16 3 6 -17 23 -16 23 1 0 18 64 48 86 39 11 -4 15 -3 10 5 -4 7
13 13 54 18 33 4 63 11 66 15 3 5 26 9 52 10 26 1 58 5 72 10 24 8 205 4 305
-7z m-60 -408 c71 -6 111 -13 135 -26 11 -6 20 -8 20 -4 0 3 6 2 13 -3 6 -6
24 -13 40 -17 15 -3 27 -12 27 -20 0 -9 8 -12 25 -8 25 4 36 -13 13 -21 -7 -2
-2 -2 12 0 14 2 39 -6 55 -17 25 -17 27 -20 10 -21 -18 0 -19 -1 -2 -8 17 -6
16 -9 -6 -33 -32 -34 -23 -43 18 -18 18 11 36 16 40 12 5 -4 5 1 2 10 -5 15
-4 16 13 5 22 -13 23 -16 11 -36 -5 -8 -14 -10 -22 -6 -11 6 -12 5 -1 -6 10
-11 15 -11 28 3 15 15 18 15 35 0 23 -20 25 -34 3 -25 -11 4 -15 1 -12 -13 2
-11 -6 -22 -20 -30 -13 -6 -24 -15 -25 -20 -1 -5 -6 -8 -10 -6 -5 1 -6 -2 -3
-7 3 -5 -1 -14 -9 -21 -8 -7 -16 -9 -16 -4 -1 5 -2 16 -3 24 -1 10 -10 14 -28
13 -19 -1 -28 3 -28 13 0 8 -3 13 -7 12 -14 -3 -83 44 -77 53 3 5 -3 6 -12 2
-11 -4 -15 -2 -11 4 6 10 -37 29 -55 25 -4 -1 -5 5 -2 13 4 8 2 15 -4 15 -6 0
-12 -6 -14 -12 -4 -10 -10 -10 -32 0 -15 6 -38 12 -51 12 -13 0 -30 6 -37 14
-11 10 -44 14 -126 13 -62 0 -112 -4 -112 -8 0 -4 -8 -8 -17 -7 -10 0 -33 -5
-50 -10 -20 -6 -33 -7 -33 -1 0 5 8 9 18 10 11 0 13 2 4 6 -8 3 -24 1 -35 -5
-12 -5 -33 -15 -47 -21 -23 -10 -23 -11 -2 -7 34 6 27 -1 -23 -23 -25 -11 -53
-18 -63 -15 -14 5 -15 4 -2 -5 8 -6 12 -11 8 -11 -5 0 -9 1 -10 2 -2 2 -17 -7
-35 -20 -17 -12 -36 -22 -42 -22 -6 0 -11 -4 -11 -10 0 -5 -11 -10 -25 -10
-24 0 -30 -9 -26 -37 3 -22 -9 -14 -65 42 l-55 55 37 32 c41 36 91 64 100 55
10 -10 -29 -58 -43 -53 -8 3 -11 1 -8 -4 3 -6 1 -10 -5 -10 -6 0 -8 -5 -4 -11
3 -6 9 -7 13 -3 3 5 23 22 44 38 28 20 37 34 34 47 -3 12 -1 17 6 12 6 -4 8 2
4 16 -5 20 -3 22 26 17 18 -3 26 -3 20 0 -21 9 -15 27 7 21 11 -3 20 -1 20 4
0 12 70 43 77 35 3 -3 12 1 20 9 8 8 22 15 31 16 9 0 28 3 42 5 14 3 50 7 80
10 30 3 55 6 56 7 3 3 115 -2 179 -7z m31 -307 c299 -92 464 -390 383 -691
-26 -95 -93 -203 -165 -266 -61 -53 -191 -119 -263 -132 -353 -65 -671 195
-671 549 0 154 47 274 149 383 66 71 176 139 261 162 84 22 224 20 306 -5z
m-616 -3 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m950 -6 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m345 -290 c25 -83 41 -185 25 -160 -7 11 -10 4 -9 -25 0 -31 2 -36 9
-20 6 14 9 0 9 -45 0 -45 -3 -59 -9 -45 -6 15 -9 9 -9 -25 0 -34 3 -40 9 -25
19 45 -4 -89 -26 -152 -20 -57 -23 -61 -19 -28 3 28 2 35 -5 25 -5 -8 -9 -33
-9 -55 1 -42 -22 -100 -36 -87 -5 4 -5 2 -1 -5 3 -6 -2 -27 -11 -46 -21 -40
-62 -47 -64 -12 -1 18 -2 18 -6 2 -3 -10 2 -26 11 -36 14 -15 13 -19 -9 -45
-14 -16 -25 -33 -25 -38 0 -5 -8 -8 -17 -7 -31 3 -36 -1 -24 -15 8 -10 9 -16
1 -21 -5 -3 -10 0 -10 7 0 7 -6 2 -13 -12 -7 -14 -21 -24 -31 -23 -11 0 -15
-4 -12 -12 5 -14 -27 -49 -36 -39 -11 10 -69 -26 -62 -38 5 -8 1 -9 -10 -5
-12 5 -16 3 -13 -5 4 -15 -76 -57 -110 -57 -12 -1 -24 -7 -27 -13 -5 -10 -7
-10 -12 0 -8 17 -25 15 -18 -2 5 -13 -2 -16 -81 -34 -49 -11 -237 -13 -230 -3
4 7 -4 11 -22 10 -25 0 -26 -1 -8 -10 14 -7 3 -8 -37 -5 -32 3 -63 8 -69 12
-6 3 -28 8 -49 9 -20 2 -62 15 -92 29 -30 14 -60 26 -66 26 -16 0 -15 17 1 24
6 3 -1 4 -16 1 -34 -7 -66 13 -45 28 9 6 7 7 -6 3 -13 -4 -24 0 -33 14 -8 12
-21 19 -28 16 -7 -2 -23 6 -35 19 -16 17 -18 24 -8 28 7 2 5 3 -5 1 -11 -1
-42 26 -87 77 -39 43 -70 81 -70 84 0 2 7 2 16 -2 14 -5 15 -3 4 17 -8 15 -15
19 -19 11 -11 -17 -52 44 -44 65 4 11 2 15 -4 11 -14 -9 -33 37 -33 81 0 25
-3 32 -9 23 -10 -16 -32 41 -33 88 -1 17 -4 28 -8 26 -3 -2 -5 52 -2 120 2 71
0 125 -5 125 -10 0 -5 59 6 75 4 6 8 24 9 41 1 17 9 49 16 70 l15 39 1 -35 2
-35 9 27 c7 18 7 33 1 44 -7 13 -5 28 6 51 17 36 33 41 52 18 11 -13 9 -19 -8
-36 -27 -25 -28 -33 -2 -17 11 7 20 16 20 20 0 5 5 8 10 8 6 0 10 -7 10 -16 0
-9 6 -14 13 -11 7 3 20 -7 28 -22 15 -25 15 -31 -3 -72 -11 -24 -24 -52 -29
-61 -12 -23 -11 -31 1 -23 16 10 12 -7 -7 -27 -10 -12 -13 -22 -8 -26 6 -4 11
-56 12 -117 0 -60 6 -130 13 -155 6 -25 12 -51 12 -57 1 -7 4 -13 7 -13 12 0
31 -58 25 -75 -5 -12 -4 -16 4 -11 12 7 21 -4 27 -35 5 -26 15 -38 15 -19 0 7
14 -8 32 -34 17 -26 37 -50 45 -53 8 -3 11 -13 8 -24 -8 -25 30 -62 56 -54 13
4 19 2 19 -9 0 -9 6 -15 13 -13 6 1 11 -6 9 -15 -2 -11 3 -18 13 -18 10 0 13
5 9 12 -4 7 -3 8 5 4 6 -4 9 -11 6 -16 -7 -12 15 -24 45 -26 14 -1 50 -11 80
-23 30 -13 71 -27 90 -33 19 -5 39 -14 44 -19 6 -5 15 -9 20 -9 6 0 4 5 -4 11
-11 8 -8 9 14 4 60 -13 154 -17 161 -6 3 6 18 11 33 10 24 0 25 -1 7 -9 -14
-6 -15 -9 -4 -9 8 -1 19 3 25 8 16 14 74 31 74 20 0 -13 20 -11 55 6 l30 14
-29 -6 c-20 -4 -27 -2 -22 5 4 6 22 14 41 18 20 3 38 10 41 16 3 5 15 6 27 3
15 -4 18 -3 9 3 -10 7 -6 12 15 21 16 6 41 23 55 36 16 15 33 22 43 19 11 -5
14 -2 9 10 -5 12 -2 15 10 10 10 -4 16 -1 16 7 0 18 30 49 48 49 17 0 52 28
52 41 0 5 -7 6 -15 3 -8 -4 -15 -12 -15 -20 0 -8 -7 -14 -16 -14 -12 0 -9 9
13 39 15 22 31 37 35 34 5 -2 8 1 8 7 0 17 59 138 70 145 6 3 10 21 10 40 0
20 6 35 15 39 9 3 14 17 14 33 l-2 28 -4 -27 c-2 -16 -8 -28 -12 -28 -4 0 -6
14 -3 30 2 17 7 30 11 30 3 0 6 62 5 138 0 99 -4 146 -15 170 -9 18 -19 29
-23 25 -5 -5 -5 8 -2 27 5 26 4 31 -3 20 -6 -8 -11 -10 -11 -5 1 22 38 55 57
49 12 -4 14 -3 5 3 -9 7 -7 14 8 31 12 12 25 22 30 22 6 0 10 8 10 18 1 25 38
-60 65 -148z m-125 -120 c0 -16 -2 -30 -4 -30 -2 0 -6 14 -8 30 -3 17 -1 30 4
30 4 0 8 -13 8 -30z m-1573 -157 c-2 -16 -4 -3 -4 27 0 30 2 43 4 28 2 -16 2
-40 0 -55z m368 -483 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m30 -30 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m-1228 -605 c57 -17 89 -47 103 -96 14 -46 13 -653 -1 -702 -22
-82 -98 -110 -294 -110 -190 0 -270 26 -295 96 -6 19 -10 160 -10 371 0 382 2
392 71 428 75 38 316 46 426 13z m2336 15 c90 -13 142 -40 156 -81 6 -18 11
-65 11 -105 l0 -74 -104 0 -105 0 -3 53 -3 52 -53 3 c-63 4 -82 -8 -82 -51 0
-45 36 -84 161 -174 186 -132 199 -154 199 -318 0 -131 -13 -166 -70 -195
-117 -60 -422 -42 -492 28 -24 25 -27 35 -30 117 l-3 90 103 3 102 3 0 -50 c0
-59 14 -71 81 -71 106 0 104 94 -4 183 -23 19 -80 63 -126 97 -49 38 -96 82
-113 109 -29 45 -29 47 -26 172 3 138 9 154 70 185 54 28 219 40 331 24z
m-1816 -23 c27 -10 65 -36 138 -91 9 -6 27 3 58 28 78 63 126 76 292 76 l145
0 0 -450 0 -450 -113 0 c-75 0 -117 4 -125 12 -9 9 -12 105 -12 369 0 238 -4
360 -10 364 -16 10 -59 -22 -73 -53 -8 -18 -14 -131 -17 -357 l-5 -330 -127
-3 -128 -3 0 313 c0 193 -4 328 -11 352 -12 42 -49 86 -74 86 -13 0 -15 -43
-15 -375 l0 -375 -125 0 -125 0 0 450 0 450 148 -1 c86 0 160 -5 179 -12z
m1197 -69 c86 -278 126 -494 126 -684 l0 -134 -115 0 c-133 0 -130 -2 -137
106 l-4 59 -50 0 -49 0 0 -82 1 -83 -128 0 -128 0 0 126 c0 197 31 367 120
667 l33 107 152 0 153 0 26 -82z m1126 -282 c0 -200 3 -371 6 -380 6 -17 50
-21 72 -8 10 7 12 90 13 380 l0 372 129 0 130 0 0 -394 c0 -261 -4 -404 -11
-425 -7 -20 -26 -40 -53 -56 -89 -52 -368 -55 -472 -5 -73 36 -74 41 -74 490
l0 390 130 0 130 0 0 -364z m928 340 c23 -10 53 -32 67 -48 23 -28 25 -37 25
-141 0 -125 -8 -150 -59 -184 l-33 -23 24 -9 c39 -15 76 -66 83 -115 4 -24 5
-91 3 -149 -3 -120 -15 -145 -84 -181 -36 -19 -59 -21 -281 -24 l-243 -3 0
451 0 452 228 -4 c192 -3 234 -7 270 -22z"/>
<path d="M5168 6603 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5255 6600 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M5081 6581 c-9 -6 1 -10 32 -12 47 -4 51 5 7 15 -14 4 -31 2 -39 -3z"/>
<path d="M5248 6563 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5840 6521 c0 -13 27 -21 68 -20 l37 0 -45 15 c-53 16 -60 17 -60 5z"/>
<path d="M4775 6440 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M6038 6403 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5738 6393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5120 6380 c0 -13 20 -13 40 0 12 8 9 10 -12 10 -16 0 -28 -4 -28
-10z"/>
<path d="M5040 6360 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M6244 6284 c3 -8 6 -18 6 -20 0 -2 5 -4 10 -4 16 0 11 27 -6 34 -12
5 -15 2 -10 -10z"/>
<path d="M4880 6280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4545 6240 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4625 6230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6017 6224 c-4 -4 8 -18 25 -31 39 -28 42 -29 26 -3 -7 11 -18 20
-25 20 -6 0 -13 5 -15 11 -2 5 -7 7 -11 3z"/>
<path d="M4625 6180 c2 -31 15 -37 15 -7 0 15 -4 27 -8 27 -5 0 -8 -9 -7 -20z"/>
<path d="M6156 6161 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"/>
<path d="M5520 6200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M5338 6193 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5435 6191 c-6 -5 -21 -11 -35 -14 -21 -4 -20 -4 8 -6 17 0 32 3 32
8 0 5 8 12 18 14 13 4 13 5 2 6 -9 0 -20 -3 -25 -8z"/>
<path d="M5175 6180 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M5610 6180 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5535 6170 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M5288 6163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5483 6163 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5830 6098 c0 -11 -8 -14 -27 -12 -16 2 -23 2 -16 -2 7 -3 9 -12 6
-20 -8 -22 44 -18 56 5 8 14 7 22 -4 31 -12 10 -15 10 -15 -2z"/>
<path d="M5125 6070 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M5710 6072 c0 -13 61 -65 68 -57 4 3 1 9 -5 13 -6 4 -12 16 -14 27
-4 20 -49 36 -49 17z"/>
<path d="M5615 6060 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"/>
<path d="M4825 6000 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M5807 5993 c-12 -11 -8 -22 12 -32 16 -9 19 -7 17 14 -1 24 -15 33
-29 18z"/>
<path d="M4832 5969 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M4980 5970 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5950 5955 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M6146 5711 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M6172 5658 c-1 -54 1 -60 10 -38 11 25 11 25 15 3 3 -13 9 -23 14
-23 7 0 8 13 4 33 -7 31 -32 87 -39 87 -2 0 -4 -28 -4 -62z"/>
<path d="M4621 5614 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4659 5483 l-2 -38 -7 35 c-7 34 -7 34 -9 -13 -1 -41 1 -46 13 -35
15 15 27 88 14 88 -4 0 -9 -17 -9 -37z"/>
<path d="M6143 5480 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M4672 5400 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6143 5295 c-1 -53 0 -95 2 -93 10 9 16 145 8 166 -6 16 -9 -7 -10
-73z"/>
<path d="M4665 5308 c-4 -16 -10 -28 -14 -28 -3 0 -7 -19 -8 -43 -2 -31 0 -37
7 -22 5 11 9 30 9 43 1 12 6 22 11 22 6 0 10 -10 11 -22 0 -13 3 -18 5 -12 2
6 0 28 -4 50 -9 38 -9 39 -17 12z"/>
<path d="M6121 5294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4620 5100 c0 -11 2 -20 4 -20 2 0 6 9 9 20 3 11 1 20 -4 20 -5 0 -9
-9 -9 -20z"/>
<path d="M6167 5100 c3 -11 7 -20 9 -20 2 0 4 9 4 20 0 11 -4 20 -9 20 -5 0
-7 -9 -4 -20z"/>
<path d="M6066 5065 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M4620 5020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6165 5010 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4725 4970 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M6073 4970 c2 -8 12 -16 23 -17 13 -2 15 -1 4 4 -8 3 -19 11 -23 17
-6 7 -7 6 -4 -4z"/>
<path d="M6115 4930 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M4770 4780 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M5797 4743 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M4875 4709 c-4 -6 -4 -13 -1 -16 3 -4 6 -1 6 6 0 8 7 10 19 6 11 -3
22 -1 26 5 4 6 -4 10 -19 10 -14 0 -28 -5 -31 -11z"/>
<path d="M5920 4709 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5840 4679 c0 -18 2 -19 15 -9 15 12 12 30 -6 30 -5 0 -9 -10 -9 -21z"/>
<path d="M4940 4683 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M5130 4681 c13 -10 13 -12 0 -22 -15 -11 -13 -11 40 2 8 2 14 -2 13
-8 -3 -15 6 -16 25 -4 14 8 -12 22 -68 36 -19 5 -22 4 -10 -4z"/>
<path d="M5778 4603 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5188 4553 c-20 -5 -24 -23 -5 -23 17 0 41 17 31 22 -5 2 -17 3 -26
1z"/>
<path d="M5230 4550 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M5463 4553 c15 -2 27 -9 27 -14 0 -5 7 -9 15 -9 8 0 15 -4 15 -10 0
-5 5 -10 10 -10 6 0 3 11 -7 25 -14 18 -26 24 -53 23 -30 -1 -31 -2 -7 -5z"/>
<path d="M5280 4540 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M5608 4543 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5378 4533 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3471 4049 c-12 -35 -14 -516 -2 -558 9 -32 43 -44 70 -25 21 15 21
21 21 299 0 156 -3 290 -6 299 -4 10 -18 16 -39 16 -28 0 -35 -5 -44 -31z"/>
<path d="M5237 4068 c-13 -48 -49 -368 -44 -383 5 -10 20 -15 52 -15 l45 0 0
84 c0 91 -27 316 -40 329 -4 5 -10 -2 -13 -15z"/>
<path d="M7232 3978 l3 -93 33 -3 c51 -5 62 11 62 88 0 94 -3 100 -56 100
l-45 0 3 -92z"/>
<path d="M7234 3697 c-2 -7 -3 -60 -2 -118 l3 -104 45 0 45 0 3 104 c2 67 -1
108 -9 117 -15 18 -78 18 -85 1z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
